import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Component/Header/Header';
import path from './Commen/Path';
import Home from './Component/Screen/Home';
import Footer from './Component/Footer/Footer';
import Contact from './Component/Screen/Contact';
import About from './Component/Screen/About';
import Service from './Component/Screen/Service';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path={path.Home} element={<Home />} />
          <Route path={path.Contact} element={<Contact />} />
          <Route path={path.About} element={<About />} />
          <Route path={path.Service} element={<Service />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
