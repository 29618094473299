import React from 'react'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll';


export default function Footer() {
  const makeCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const openEmail = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500
    });
  };

  const openMap = (address) => {
    // For opening Google Maps, replace spaces with + for the query
    const mapAddress = address.replace(/\s/g, '+');
    window.location.href = `https://www.google.com/maps/search/?api=1&query=${mapAddress}`;
  };

  return (
    <footer className="bg-body-tertiary text-center text-lg-start footermain">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4 mb-md-0 footer_left">
            <div className="footerLogo">
              <Link to={process.env.REACT_APP_your_Company_Name}>
                {/* eslint-disable-next-line */}
                <img
                  src="./Logo.png"
                  className="img-fluid"
                  alt="Image is not found...!"
                /></Link>
            </div>

            <p className='mt-3 mt-sm-3 px-2 p' style={{ fontFamily: "Rubik", color:"#2E2E2E",fontWeight:700 }}>
            In 2023, the journey of Artwave Technology commenced in Ahmedabad, founded by Chenil Thummar and Pinal Sutariya. Their vision was to be the catalyst for startups, fostering their evolution into thriving brands. Our growth from a fledgling startup to a global IT powerhouse mirrors the transformative stories we've crafted for our clients over the years.
            </p>
            <div className="footerIcon d-flex flex-row  justify-content-sm-start justify-content-center ">

              <div className="instagram iconReper ">
                <Link to={process.env.REACT_APP_instagram} target='_blank'> <i className="bi bi-instagram"></i> </Link>
              </div>

              <div className="facebook iconReper">
                <Link to={process.env.REACT_APP_facebook} target='_blank'> <i className="bi bi-facebook"></i> </Link>
              </div>

              <div className="twitter iconReper">
                <Link to={process.env.REACT_APP_linkedin} target='_blank'>  <i className="bi bi-linkedin"></i> </Link>
              </div>

            </div>
          </div>

          <div className="col-lg-6 col-md-12 mb-4 mb-md-0 footerRight pt-0 pt-sm-5  ">
            <div className="footer_right_one  ps-0 ps-sm-5">
              <h5 className="text-uppercase" style={{fontFamily:"Teko", color:"#3D3D3D"}}>Ahmedabad </h5>
              <div className="rightContect">
                <div className="call contact" onClick={() => makeCall(process.env.REACT_APP_call)}>
                  <i className="bi bi-telephone-fill"></i>
                  <p  style={{fontFamily:"Rubik", color:"#2E2E2E"}}>{process.env.REACT_APP_call}</p>
                </div>
                <div className="email contact" onClick={() => openEmail(process.env.REACT_APP_mail)}>
                  <i className="bi bi-envelope-fill"></i>
                  <p style={{fontFamily:"Rubik", color:"#2E2E2E"}}> {process.env.REACT_APP_mail}</p>
                </div>

                <div className="address contact" onClick={() => openMap(process.env.REACT_APP_address)} >
                  <i className="bi bi-geo-alt-fill"></i>
                  <p className='FooterAdd'style={{fontFamily:"Rubik", color:"#2E2E2E"}}>{process.env.REACT_APP_address}</p>
                </div>
              </div>
            </div>

            <div className="footer_right_two  ps-0 ps-sm-5">
              <h5 className="text-uppercase" style={{fontFamily:"Teko", color:"#3D3D3D"}}>surat </h5>
              <div className="rightContect">
                <div className="call contact" onClick={() => makeCall(process.env.REACT_APP_call2)} >
                  <i className="bi bi-telephone-fill"></i>
                  <p style={{fontFamily:"Rubik", color:"#2E2E2E"}}>{process.env.REACT_APP_call2}</p>
                </div>
                <div className="email contact" onClick={() => openEmail(process.env.REACT_APP_mail2)}>
                  <i className="bi bi-envelope-fill"></i>
                  <p style={{fontFamily:"Rubik", color:"#2E2E2E"}}> {process.env.REACT_APP_mail}</p>
                </div>
                <div className="address contact " onClick={() => openMap(process.env.REACT_APP_address2)}>
                  <i className="bi bi-geo-alt-fill"></i>
                  <p className='FooterAdd' style={{fontFamily:"Rubik", color:"#2E2E2E"}}>{process.env.REACT_APP_address2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" p-3 footerlink ">
        <span className='footer_Top_line'></span>
        <div className='d-flex justify-content-between w-100'>
          <div>
            <Link to={"https://wa.me/9099136235"} target='_blank' style={{ position: "fixed", bottom: "10px", zIndex: "1000000" }}>
              <img src='./Images/WhatsApp_icon.png.webp' width={50} height={50}></img>
            </Link>
          </div>
          <div className="bottam text-center">
            © 2023 All Rights Reserved.
            <Link className="text-body footerLink_a" to={process.env.REACT_APP_your_Company_Name}>
              ARTWAVE TECHNOLOGY
            </Link>
          </div>
          <div onClick={scrollToTop} style={{ cursor: "pointer", zIndex: "1000000" }}>
            <Link style={{ position: "fixed", right: "10px", bottom: "10px", background: "white", borderRadius: "360px" }}>
              <img src='./Images/back-to-top.png' width={50} height={50} ></img>
            </Link>
          </div>
        </div>
      </div>
      {/* Copyright */}
    </footer >

  )
}
