import React, { useEffect } from "react";
import WOW from "wowjs";

export default function CompnyBenifits() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <>
      <div className="container py-5">
        <div className="text-center  wow zoomIn">
          <span className="what">
            <img
              className="pe-1"
              src="./Images/Group 42.png"
              alt="Company Benefits"
            />
            Company Benefits
          </span>
          <h1 className="font-bold" style={{ color: "rgba(57, 51, 123, 1)" }}>
            Why Should Choose
            <br /> Our Agency?
          </h1>
        </div>

        <div className="row px-md-5 mt-5">
          <div className="col-md-8 Pragraph wow fadeInLeft">
            <p
              style={{
                fontFamily: "Rubik",
                color: "#3D3D3D",
                fontSize: "0.8em",
                lineHeight: "1.6em",
                fontWeight: 600,
              }}
            >
              Choosing our agency,{" "}
              <span
                className="text-danger"
                style={{ fontFamily: "Rubik", fontSize: "0.9em" }}
              >
                ArtWave Technology
              </span>
              , means opting for excellence in every aspect of your digital
              journey. Our dedicated team of professionals is committed to
              transforming your ideas into impactful realities. With a perfect
              blend of creativity, innovation, and technical expertise, we craft
              solutions that not only meet but exceed your expectations.{" "}
              <span
                className="text-danger"
                style={{ fontFamily: "Rubik", fontSize: "0.9em" }}
              >
                At ArtWave
              </span>
              , client satisfaction is our priority, and we strive to build
              lasting partnerships. From cutting-edge graphic design to
              result-driven digital marketing and seamless web development, our
              comprehensive services ensure that your brand stands out in the
              dynamic digital landscape. Trust{" "}
              <span
                className="text-danger"
                style={{ fontFamily: "Rubik", fontSize: "0.9em" }}
              >
                ArtWave Technology
              </span>{" "}
              for a collaborative and forward-thinking approach that turns your
              vision into success.
            </p>

            <div className="py-3 d-none d-md-flex justify-content-start flex-wrap gap-md-0 gap-lg-5">
              <div className="align-items-center">
                {/*  */}
                <div
                  className="main_wh  d-flex gap-2 p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Startup Assistance
                </div>
                {/*  */}
                <div
                  className="main_wh   d-flex gap-2 p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Corporate Mastery
                </div>
                {/*  */}
                <div
                  className="main_wh   d-flex gap-2 p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Government Collaboration
                </div>
              </div>
              <div className="align-items-center ">
                {/*  */}
                <div
                  className="main_wh  d-flex gap-2 p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  E-commerce Specialists
                </div>
                {/*  */}
                <div
                  className="main_wh   d-flex gap-2 p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Tailored Solutions
                </div>
                {/*  */}
                <div
                  className="main_wh   d-flex gap-2 p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Demonstrated Success
                </div>
              </div>
            </div>
            <div className="py-3 d-flex d-md-none  justify-content-between flex-wrap gap-2 ">
              <div className="align-items-center ">
                {/*  */}
                <div
                  className="main_wh  d-flex  p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Startup Assistance
                </div>
                {/*  */}
                <div
                  className="main_wh   d-flex  p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Corporate Mastery
                </div>
              </div>
              <div className="align-items-center ">
                {/*  */}
                <div
                  className="main_wh   d-flex  p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Government Collaboration
                </div>
                {/*  */}
                <div
                  className="main_wh  d-flex  p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  E-commerce Specialists
                </div>
              </div>
              <div className="align-items-center w-100  d-flex flex-column justify-content-center">
                {/*  */}
                <div
                  className="main_wh   d-flex  p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Tailored Solutions
                </div>
                {/*  */}
                <div
                  className="main_wh   d-flex  p-1"
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "0.7em",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  <span>
                    <img src="./Images/Group.png" alt="sad" />
                  </span>
                  Demonstrated Success
                </div>
              </div>
            </div>

            <div className="Trust-line mt-4 mb-3">
              Trust the expert at ArtWave Technology for all your web design and
              development needs.
            </div>
          </div>

          <div className="col-md-4 Two-img mt-md-3 wow fadeInRight">
            <img
              src="/Images/Group 31.png"
              className="img-fluid imgTwo"
              alt="Image Not Found"
            />
          </div>
        </div>
      </div>
    </>
  );
}
