import React, { useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ScrollTrigger from 'react-scroll-trigger';
import WOW from "wowjs"





export default function Questions() {

    const windowScrollY = window.scrollY
    useEffect(() => {
        if (window.scrollY === 2980) {
            new WOW.WOW({
                live: false
            }).init();
        }
    }, [windowScrollY])

    const [percentage, setPercentage] = useState(0);
    const [isCounterStarted, setIsCounterStarted] = useState(false);
    const [Start, setStart] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition >= 2900 && !isCounterStarted) {
                setIsCounterStarted(true);
                const intervalId = setInterval(() => {
                    setPercentage((prevPercentage) => Math.min(prevPercentage + 1, 99));
                }, 60);
                return () => {
                    clearInterval(intervalId);
                };
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isCounterStarted, Start]);

    console.log(Start);


    return (
        <>
            <div className='question-sec'>
                <div className="container-fluid pt-3 pb-5 justify-content-center ">
                    <div className="text-center mb-5 wow zoomIn">
                        <span className='what'>
                            <img className='pe-1' src="./Images/Group 42.png" alt="Company Benefits" />
                            You've have Any Questions?
                        </span>
                        <h1 className='font-bold' style={{ color: "rgba(57, 51, 123, 1)" }}>
                            Frequently asked<br /> questions
                        </h1>
                    </div>

                    <div className='row my-3 ms-4 align-items-center justify-content-center '>
                        <div className='col-12 col-sm-6 col-lg-6  mt-3 wow fadeInLeft align-items-center'>
                            <div className='que-list py-3 px-3  mb-4' >
                                <span className='que-No' >1. </span>
                                What and why is digital marketing?
                            </div>
                            <div className='que-list py-3 px-3 mb-4 '>
                                <span className='que-No'>2. </span>
                                What is graphic design?
                            </div>
                            <div className='que-list py-3 px-3 mb-4'>
                                <span className='que-No'>3. </span>
                                We visualize excellence and drive growth through digital mastery?
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-3 '>
                            <div className="row ms-sm-4 text-center align-items-center">
                                <div className='col-12 col-sm-8 col-lg-12'>
                                    <img className="img-fluid" src="/Images/Rectangle 20.png" alt="Not Found" />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-3 pt-4 pt-sm-0'>
                            <ScrollTrigger onEnter={() => setStart(true)} onExit={() => setStart(false)}>
                                <div className='col-12 col-sm-6  round-percent text-center '>
                                    <div className='text-center d-flex flex-wrap flex-column justify-content-center align-items-center'>
                                        <div className='percetn-round '>
                                            {
                                                Start && <CircularProgressbar
                                                    pause={true}
                                                    value={Start ? percentage : 0}
                                                    text={`${Start && percentage}%`}
                                                    styles={{
                                                        root: {},
                                                        path: {
                                                            stroke: `rgba(220, 33, 48, ${percentage / 100})`,
                                                            strokeLinecap: 'butt',
                                                            transition: 'stroke-dashoffset 0.5s ease 0s',
                                                            transform: 'rotate(0turn)',
                                                            transformOrigin: 'center center',
                                                            strokeWidth: 4,
                                                        },
                                                        trail: {
                                                            stroke: 'rgba(57, 51, 123, 1)',
                                                            strokeWidth: 4,
                                                            strokeLinecap: 'butt',
                                                            transform: 'rotate(0.25turn)',
                                                            transformOrigin: 'center center',
                                                        },
                                                        text: {
                                                            fill: 'rgba(57, 51, 123, 1)',
                                                            fontSize: '1.7rem',
                                                        },
                                                        background: {
                                                            fill: 'rgba(57, 51, 123, 1)',
                                                        },
                                                    }}
                                                />
                                            }
                                        </div>
                                        <div className='precent-text'>Quality of Work</div>
                                    </div>
                                    <div className='text-center d-flex flex-column justify-content-center align-items-center'>

                                        <div className='percetn-round '>
                                            {
                                                Start && <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={{
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                            // Path color
                                                            stroke: `rgba(220, 33, 48, ${percentage / 100})`,
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'butt',
                                                            // Customize transition animation
                                                            transition: 'stroke-dashoffset 0.5s ease 0s',
                                                            // Rotate the path
                                                            transform: 'rotate(0turn)',
                                                            transformOrigin: 'center center',
                                                            strokeWidth: 4,
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                            // Trail color
                                                            stroke: 'rgba(57, 51, 123, 1)',
                                                            strokeWidth: 4,
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'butt',
                                                            // Rotate the trail
                                                            transform: 'rotate(0.25turn)',
                                                            transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                            // Text color
                                                            fill: 'rgba(57, 51, 123, 1)',

                                                            // Text size
                                                            fontSize: '1.7rem',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                            fill: 'rgba(57, 51, 123, 1)',
                                                        },
                                                    }}
                                                />
                                            }
                                        </div>
                                        <div className='precent-text '>Satisfied clients</div>
                                    </div>
                                </div>
                            </ScrollTrigger>
                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}
