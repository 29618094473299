import React, { useEffect } from 'react'
import WOW from "wowjs"

export default function Cart({ titleImage, title, bodyText }) {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])
    return (
        <div className="col-12 col-sm-12 col-md-6 col-lg-4  pt-4 wow fadeIn">
            <div className="card card_shadow">
                <div className="card-body">
                    <div className="cardheader">
                        {/* eslint-disable-next-line  */}
                        <img src={titleImage} alt="Image is not found...!" />
                        <h5 className="card-title">{title}</h5>
                    </div>
                    <p className="card-text cardtext">
                      {bodyText}
                    </p>
 
                </div>
            </div>
        </div>

    )
}
