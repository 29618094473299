import React, { useEffect } from 'react'
import WOW from "wowjs"
import WnAService from '../HomeComponent/WnAService'
import Elevate from '../AboutComponents/Elevate'
import ServiceBuiltSB from '../AboutComponents/Service_Built_SB'
import CardData from '../AboutComponents/CardData'
import HappyCutomers from '../HomeComponent/HappyCutomers';
import Citys from '../HomeComponent/Citys'
import Accordion from '../HomeComponent/Accordion'
import Distinctiveness from '../HomeComponent/Distinctiveness'
import Marqee from '../Header/Marquee'

export default function About() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  const post2Styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    },
    post2_img: {
      width: '100%',
      objectFit: 'contain',
      filter: "brightness(50%)",
    },
    post2_div: {
      color: 'white',
      position: 'absolute',
      fontSize: '3vw',
      zIndex: '555',
      textAlign: "center",
      top: "18px"
    },
    post2_h1: {
      color: 'white',
      position: 'absolute',
      fontSize: '5vw',
      zIndex: '555',
      textAlign: "center",
    },
    colorSpan: {
      color: " #F08518"
    },
    p: {
      color: 'white',
      position: 'absolute',
      fontSize: '1.6em',
      zIndex: '555',
      textAlign: "justify",
      width: "80%",
      lineHeight: "normal",
      marginTop: "7vh"
    }
  };

  return (
    <div className='About_container'>

      {/* section 1  */}
      <div className="row p-0 m-0 wow fadeIn">
        <div className="col-12 p-0 m-0 position-relative" style={post2Styles.container}>
          {/* eslint-disable-next-line */}
          <img src="./Images/architectcafecasualbrainstormingmeetingconcept-1@2x.png" alt="Image is not found ...!" className='img-fluid' style={post2Styles.post2_img} />
          <h1 className='post_h1' style={post2Styles.post2_h1}>
            EXPLORE THE <span style={{ color: "#F08518" }}>STORY</span> OF <br />
            ARTWAVE TECHNOLOY
          </h1>
        </div>
      </div>


      <WnAService /> {/* section 2  */}

      <div className='About_backgroundImg'>
        <Elevate />          {/* section 3  */}
        <ServiceBuiltSB />   {/* section 4  */}
        <CardData />         {/* section 5  */}
      </div>

      {/* section 5  */}
      <div className='row m-0 wow fadeIn about_Images' style={{ position: 'relative' }}>
        <div className="overlay"></div>
        <img src='./Images/people-meeting-discussion-sharing-studying-concept 1.png' alt="" className='img-fluid p-0' style={{ width: "100%", objectFit: "containt" }} />
        <div
          className="  A_text font-weight-bold "
          style={{ position: "absolute", textAlign: "center", color: "white" }}
        >
          <h1  className='FFS'>
            Empowering Success through <span style={{ color: "#F08518" }}>Innovative Design</span> and <br />
            <span style={{ color: "#F08518" }}> Marketing</span>Marketing Excellence at ArtWave Technology.
          </h1>
        </div>
      </div>


      <HappyCutomers />    {/* section 6 */}
      <Citys />            {/* section 7 */}
      <Accordion />        {/* section 8 */}
      <Distinctiveness />  {/* section 9 */}

      <div className="row p-0 m-0 wow fadeIn">
        <div className="col-12 p-0 m-0 position-relative" style={post2Styles.container}>
          <img src="./Images/5937434_3085411 1.png" alt="not found ...!" className='img-fluid' style={post2Styles.post2_img} />
          <h1 className='post_h1' style={post2Styles.post2_div}>
            Embark on the ArtWave Technology <span style={post2Styles.colorSpan}>  Journey </span> – Where Creativity Meets <span style={post2Styles.colorSpan}>  Innovation. </span>
          </h1>
          <p style={post2Styles.p} className='perent d-md-block d-none'>Joining ArtWave Technology is more than a partnership; it's becoming a valued member of a family that genuinely cares about the success of your venture. Whether you're a startup, business, or government agency, our unwavering commitment is to transform your aspirations into reality. At ArtWave, your goals become our mission, and we are ready to embark on a journey of innovation and success together.</p>
        </div>
      </div>




      <Marqee />


      {/* <CircularProgressCountUp/> */}



    </div>
  )
}
