import React, { useEffect } from 'react'
import WOW from "wowjs"
import path from '../../Commen/Path';
import { Link } from 'react-router-dom';

export default function YourProject() {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])

    return (
        <>
            <div className="container px-sm-5 mb-5 wow fadeIn">
                <div className="container px-sm-5 px-0">
                    <div className="your-project d-flex flex-wrap justify-content-between align-items-center">
                        <div>
                            <h1>
                                LET'S GET YOUR PROJECT <br /> STARTED!
                            </h1>
                        </div>
                        <div>
                            <div className="elementor-element elementor-element-6326ccd elementor-widget elementor-widget-tm-ele-button"
                                data-id="6326ccd"
                                data-element_type="widget"
                                data-widget_type="tm-ele-button.default">
                                <div
                                    style={{ backgroundColor: "rgba(57, 51, 123, 1)" }}
                                    className="elementor-widget-container">
                                    <div
                                        className="tm-sc-button btn-view-details ">
                                        <Link to={path.Contact}
                                            className="btn text-light btn-theme-colored1 ">
                                            <span style={{fontSize:"1.5rem"}} >
                                                Contact with us
                                            </span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
