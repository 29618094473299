import React, { useEffect, useState } from 'react'
import '../Header/Header.css'
import { Link, useLocation } from 'react-router-dom';
import path from '../../Commen/Path';

export default function Header() {
  const Location = useLocation();
  const [activeLink, setActiveLink] = useState(Location.pathname === '/' ? 'Home' : Location.pathname === '/about' ? 'about' : Location.pathname === '/Service' ? 'Services' : 'Contact');
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    setActiveLink(Location.pathname === '/' ? 'Home' : Location.pathname === '/about' ? 'about' : Location.pathname === '/Service' ? 'Services' : 'Contact');
  }, [Location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setNavbarCollapsed(true); // Close the navbar after a link is clicked
  };

  const handleNavbarToggle = () => {
    setNavbarCollapsed(!navbarCollapsed);
  };
  
  return (
    <>
      <nav className="navbar text-center justify-content-center navbar-expand-lg bg-body-tertiary" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px" }} >
        <div className="container-fluid">
          <div className='col-4 text-center'>
            <img src="./Logo.png" alt="" style={{ width: "105px", height: "43px" }} />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleNavbarToggle} 
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse text-sm-center navbar-collapse h_menu ${navbarCollapsed ? 'collapse' : 'show'}`} id="navbarNav" >
            <ul className="navbar-nav">

              <li className="nav-item">
                <Link style={{ textDecoration: "none" }}>
                  {/* eslint-disable-next-line */}
                  <Link to={path.Home} aria-current="page"
                    className={`nav-link ${activeLink === 'Home' ? 'active' : ''}`}
                    onClick={() => handleLinkClick('Home')}
                    style={{
                      color: "rgba(57, 51, 123, 1)", fontWeight: "500", marginRight: "22px", borderBottom:
                        activeLink === 'Home'
                          ? '2px solid rgba(220, 32, 47, 1)'
                          : 'none',
                          padding : "12px 0px"
                    }}>
                    Home
                  </Link>
                </Link>
              </li>

              <li className="nav-item">
                {/* eslint-disable-next-line */}
                <Link className={`nav-link ${activeLink === 'about' ? 'active' : ''}`}
                  onClick={() => handleLinkClick('about')}
                  to={path.About}
                  style={{
                    color: "rgba(57, 51, 123, 1)", fontWeight: "500", marginRight: "22px", borderBottom:
                      activeLink === 'about'
                        ? '2px solid rgba(220, 32, 47, 1)'
                        : 'none',
                        padding : "12px 0px"
                  }}>
                  About
                </Link >

              </li>
              <li className="nav-item">
                {/* eslint-disable-next-line */}
                <Link className={`nav-link ${activeLink === 'Services' ? 'active' : ''}`}
                  onClick={() => handleLinkClick('Services')}
                  to={path.Service}
                   style={{
                    color: "rgba(57, 51, 123, 1)", fontWeight: "500", marginRight: "22px", borderBottom:
                      activeLink === 'Services'
                        ? '2px solid rgba(220, 32, 47, 1)'
                        : 'none',
                        padding : "12px 0px"
                  }}>
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link style={{ textDecoration: "none" }}>
                  {/* eslint-disable-next-line */}
                  <Link to={path.Contact} className={`nav-link ${activeLink === 'Contact' ? 'active' : ''}`}
                    onClick={() => handleLinkClick('Contact')}
                    style={{
                      color: "rgba(57, 51, 123, 1)", fontWeight: "500", marginRight: "22px", borderBottom:
                        activeLink === 'Contact'
                          ? '2px solid rgba(220, 32, 47, 1)'
                          : 'none',
                          padding : "12px 0px"
                    }}>
                    Contact
                  </Link>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>



    </>
  )
}


// import React, { useEffect, useState } from 'react';
// import '../Header/Header.css';
// import { Link, useLocation } from 'react-router-dom';
// import path from '../../Commen/Path';

// export default function Header() {
//   const Location = useLocation();
//   const [activeLink, setActiveLink] = useState(Location.pathname === '/' ? 'Home' : Location.pathname === '/about' ? 'about' : Location.pathname === '/Service' ? 'Services' : 'Contact');
//   const [navbarCollapsed, setNavbarCollapsed] = useState(true);

//   useEffect(() => {
//     setActiveLink(Location.pathname === '/' ? 'Home' : Location.pathname === '/about' ? 'about' : Location.pathname === '/Service' ? 'Services' : 'Contact');
//   }, [Location.pathname]);

//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//     setNavbarCollapsed(true); // Close the navbar after a link is clicked
//   };

//   const handleNavbarToggle = () => {
//     setNavbarCollapsed(!navbarCollapsed);
//   };

//   return (
//     <>
//       <nav className="navbar text-center justify-content-center navbar-expand-lg bg-body-tertiary" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px" }}>
//         <div className="container-fluid">
//           <div className='col-4 text-center'>
//             <img src="./Logo.png" alt="" style={{ width: "108px", height: "49px" }} />
//           </div>
//           <button
//             className="navbar-toggler"
//             type="button"
//             onClick={handleNavbarToggle} // Toggle the navbar state on button click
//           >
//             <span className="navbar-toggler-icon" />
//           </button>
//           <div className={`collapse text-sm-center navbar-collapse h_menu ${navbarCollapsed ? 'collapse' : 'show'}`} id="navbarNav">
//             <ul className="navbar-nav">
//               <li className="nav-item">
//                 <Link to={path.Home} className={`nav-link ${activeLink === 'Home' ? 'active' : ''}`} onClick={() => handleLinkClick('Home')}>
//                   Home
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link to={path.About} className={`nav-link ${activeLink === 'about' ? 'active' : ''}`} onClick={() => handleLinkClick('about')}>
//                   About
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link to={path.Service} className={`nav-link ${activeLink === 'Services' ? 'active' : ''}`} onClick={() => handleLinkClick('Services')}>
//                   Services
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link to={path.Contact} className={`nav-link ${activeLink === 'Contact' ? 'active' : ''}`} onClick={() => handleLinkClick('Contact')}>
//                   Contact
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </>
//   )
// }
