import React,{useEffect} from 'react'
import WOW from "wowjs"

export default function Poster2() {

  useEffect(() => {
    new WOW.WOW({
        live: false
    }).init();
}, [])


  const post2Styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    },
    post2_img: {
      width: '100%',
      objectFit: 'contain',
      filter: "brightness(50%)",
    },
    post2_h1: {
      color: 'white',
      position: 'absolute',
      fontSize: '5vw',
      zIndex: '555',
      textAlign: "center"
    },
    colorSpan: {
      color: " #F08518"
    }
  };

  return (
    <div className='container-fluid p-0 m-0'>
      <div className="row p-0 m-0 wow fadeIn">
        <div className="col-12 p-0 m-0 position-relative" style={post2Styles.container}>
          <img src="./Images/dark-brown-coffee-cup 1.png" alt="not found ...!" className='img-fluid' style={post2Styles.post2_img} />
          <h1 className='post_h1' style={post2Styles.post2_h1}>
            ArtWave Technology.
            <span style={post2Styles.colorSpan}>  Your Digital Partner</span> For
            <br />
            Innovation and impact.
          </h1>
        </div>
      </div>
    </div>
  );
}
