import React, { useEffect } from 'react'
import WOW from "wowjs"

export default function Elevate() {

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])


    return (
        <div className="container-fluid pt-5 Elevate_Repor" >

            <div className="Right_Bc_Imag">
                <img src="./Images/side design 3.png" className='img-fluid' alt="img not Found...! " />
            </div>

            <div className="container">
                <div className='row ' >
                    {/* Left part */}
                    <div className="col-12 col-md-12 col-lg-6 Elevate_left px-4 wow fadeInLeft" >
                        <div className="row">
                            <div className="col-12 ">
                                <h2 style={{ fontSize: "3.4vw" }}>
                                    Elevate with Top-tier Design
                                    Solutions <span>  .</span>
                                </h2>
                            </div>
                            <div className="col-12 d-flex justify-content-around about-two-button">
                                <div className='col-5 p-0'>
                                    <button type="button" className="p-3 btn borderButton">Our Mission</button>
                                </div>
                                <div className='col-5 p-0'>
                                    {/* <button type="button" className="p-3 btn discover btn-danger">Discover More</button> */}
                                    <div className="wna_right_Button Sec  wow pulse bg-danger rounded">
                                        <div class="elementor-widget-wrap elementor-element-populated ">
                                            <div class="elementor-widget-container rounded ">
                                                <div class="tm-sc-button rounded text-center btn-view-details justify-content-center  bg-danger">
                                                    <span class="btn rounded text-light btn-theme-colored1 " style={{ fontSize: "1rem", padding: "10px", textAlign: "center" }}>
                                                        Our Vision
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <button type="button" className="btn btn-danger" style={{ position: "relative", top: "-30px" }}>Discover More</button> */}
                                    </div>
                                </div>
                                {/* <button type="button" className="btn borderButton">Our Mission</button>
                                <button type="button" className="btn btn-danger" >Discover More</button> */}
                            </div>
                            <div className="col-12 text">
                                <p>
                                    At ArtWave Technology, our mission revolves around simplifying the path for startups, businesses, and government agencies. We specialize in delivering innovative web technology services that demystify the digital landscape. Whether you're a quaint neighborhood store, a corporate titan, or a government entity, we are dedicated to making technology an effortless ally on your journey.
                                </p>
                            </div>

                        </div>
                    </div>

                    {/* Right part */}
                    <div className="col-12 col-md-12 col-lg-6 Elevate_right px-4 wow fadeInRight">
                        <div className='row'>
                            <div className=" col-12 text">
                                <p className=''>
                                    Aligning with ArtWave Technology is akin to entering a community that values your startup, business, or government agency as deeply as you do. Our pledge to your triumph is steadfast, and we stand prepared to actualize the path that transforms your dreams into tangible achievements."
                                </p>
                            </div>
                            <div className='cal-12  wna_right_imageToMark'>
                                {/* eslint-disable-next-line */}
                                <img src="./Images/sketches-laptop-working-edit-laptop-drawing 1.png" className='img-fluid w-100 h-100' alt="Image not Found...! " />
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>


    )
}
