import React, { useEffect } from "react";
import WOW from "wowjs";
import "./WnAs.css";
import { Link } from "react-router-dom";
import path from "../../Commen/Path";

export default function WnAService() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div className="position-relative">
      <div className="container my-5 exMargin">
        {/* eslint-disable-next-line */}
        <img
          src="./Images/SIDE DSIGN1.png"
          className="img-fluid  wna_sideDign wow fadeInLeftBig"
          alt="Image not Found...! "
        />

        <div className="row wnaservice_main ">
          {/* Left part */}
          <div className="col-12 col-md-12 col-lg-6 wna_leftMain">
            <div className="row">
              <div className="col-12 wna_main_Iage p-3 mt-3">
                <div className="bg-white">
                  <img
                    src="./Images/Rectangle 3.png"
                    className="img-fluid wna_sine_image wna_main_Iage1 wow fadeInLeftBig"
                    alt="not Found...! "
                  />
                </div>
                <div className="  wna_main_fixImg">
                  <img
                    src="./Images/Rectangle 4.jpg"
                    className="img-fluid wna_sine_image "
                    alt="not Found...! wow fadeInDown"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right part */}
          <div className="col-12 col-md-12 col-lg-6 right wow fadeInRight ">
            <h2 className="wna_right_h2 w-100" style={{color:"#39337B"}}>
              We provide the best
              <br />web and app services{" "}
            </h2>
            <p className="wna_right_p">
              ArtWave Technology is your gateway to unparalleled excellence in
              web and app services. With a commitment to innovation and
              precision, we craft digital experiences that elevate your brand to
              new heights. Partner with us for the best in technology-driven
              solutions, setting the stage for your success in the digital
              landscape.
            </p>
            <div className="wna_right_imageToMark layer-image-inner-wrapper image-hover-effect-shine">
              <div class="layer-image-inner-wrapper image-hover-effect-shine">
                <div className="Trust-line align-items-center">
                  {/*  */}
                  <div className="main_wh  d-flex gap-1 p-1">
                    <div className="IMG_MAIN">
                      <img src="./Images/Group.png" className=" IMG1" alt="sad" />
                    </div>
                    <p style={{fontFamily:"Rubik", fontSize:"0.8em",color:"#3D3D3D",fontWeight:600}}>Effortless excellence in premium web design at ArtWave Technology.</p>
                  </div>
                  {/*  */}
                  <div className="main_wh   d-flex gap-2 p-1">
                    <div className="IMG_MAIN">
                      <img src="./Images/Group.png" className=" IMG1" alt="sad" />
                    </div>
                    <p style={{fontFamily:"Rubik", fontSize:"0.8em",color:"#3D3D3D",fontWeight:600}} >
                    ArtWave Technology: Where Innovation Transforms into Results
                    – Elevate with Excellence.
                    </p>
                  </div>
                  {/*  */}
                  <div className="main_wh   d-flex gap-2 p-1">
                    <div className="IMG_MAIN">
                      <img src="./Images/Group.png" className=" IMG1" alt="sad" />
                    </div>
                    <p style={{fontFamily:"Rubik", fontSize:"0.8em",color:"#3D3D3D",fontWeight:600}}>
                    At ArtWave Technology, innovation begins with thorough
                    research and development.
                    </p>
                  </div>
                  {/*  */}
                  <div className="main_wh   d-flex gap-2 p-1">
                    <div className="IMG_MAIN">
                      <img src="./Images/Group.png" className=" IMG1" alt="sad" />
                    </div>
                    <p style={{fontFamily:"Rubik", fontSize:"0.8em",color:"#3D3D3D",fontWeight:600}}>
                    At ArtWave Technology, we craft quality design ideas that
                    resonate with innovation and creativity.
                    </p>
                  </div>
                </div>
                {/* <img fetchpriority="high" decoding="async" class="img-fluid" src="./Images/Group 28.png" width="100%" height="630" alt="Image"/> */}
              </div>
              {/* eslint-disable-next-line */}
              {/* <img src="./Images/Group 28.png" className='img-fluid' alt="Image not Found...! " /> */}
            </div>

            <div className="width_768">
              <div className="wna_right_Button  wow pulse bg-danger rounded">
                <div class="elementor-widget-wrap elementor-element-populated ">
                  <div
                    class="elementor-element elementor-element-6326ccd elementor-widget elementor-widget-tm-ele-button"
                    data-id="6326ccd"
                    data-element_type="widget"
                    data-widget_type="tm-ele-button.default"
                  >
                    <div class="elementor-widget-container rounded bg-danger">
                      <div class="tm-sc-button rounded btn-view-details justify-content-center  bg-danger">
                        <Link
                          to={path.About}
                          class="btn rounded text-light btn-theme-colored1 bg-danger"
                        >
                          <span style={{ fontSize: "1.4rem" }}>
                            Discover More
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button type="button" className="btn btn-danger" style={{ position: "relative", top: "-30px" }}>Discover More</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
