import React, { useEffect } from "react";
import WOW from "wowjs";
import YourProject from "../HomeComponent/YourProject";
import CustomSlider from "../HomeComponent/Slider";
import Marqslider from "../HomeComponent/Marqslider";

export default function Marqee() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const post2Styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    },
    post2_img: {
      width: '100%',
      objectFit: 'contain',
      filter: "brightness(50%)",
    },
    post2_h1: {
      color: '#39337B',
    //   position: 'absolute',
      fontSize: '4vw',
      zIndex: '555',
      textAlign: "center"
    },
    colorSpan: {
      color: " #F08518"
    }
  };


  return (
    <div className="Home_backgroundImg">
      <div className="sideImage wow fadeInLeft">
        {/* eslint-disable-next-line */}
        <img
          src="./Images/SIDE DSIGN1.png"
          className="img-fluid  wna_sideDign2"
          alt="Image not Found...! "
        />
      </div>

      <div className="text-center wow zoomIn">
        <span className="what" style={{ fontSize: "1.4rem" }}>
          <img
            className="pe-1"
            src="./Images/Group 42.png"
            alt="Company Benefits"
          />
          Dur Cherished
        </span>
        <h1
          className="font-bold"
          style={{
            color: "rgba(57, 51, 123, 1)",
            // fontSize: "1.7em",
            margin: "0px",
            marginBottom: "-18px",
          }}
        >
          CUSTOMER FEEDBACKS
        </h1>
      </div>
      <CustomSlider />
     
         <Marqslider />
    
      <div style={{marginTop:"25px"}}>
        <YourProject />
      </div>
    </div>
  );
}
