import React, { useEffect } from "react";
import WOW from "wowjs";

export default function Marqslider() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const post2Styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    },
    post2_img: {
      width: '100%',
      objectFit: 'contain',
      filter: "brightness(50%)",
    },
    post2_h1: {
      color: '#39347B',
      fontSize: '3em',
      zIndex: '555',
      textAlign: "center"
    },
    colorSpan: {
      color: " #F08518"
    }
  };
  
  return (
    <div className="container-fluid  p-0 m-0">
      <div className="row p-0 m-0 wow fadeIn">
        <div className="col-12 p-0 m-0 position-relative" style={post2Styles.container}>
          <h1 className='post_h1' style={post2Styles.post2_h1}>
            "Our collaborative ventures include partnerships <br /> with adiverse range of clients."
          </h1>
        </div>
      </div>

      <div style={{ width: "80%", margin: "0px auto" }}>
        <marquee behavior="" direction="left" >
          <img src="./Images/Images/Artboard 1@3x 1.png" alt="" width="150px" height="110px" className="me-5" />
          <img src="./Images/Images/Artboard 1@3x 2.png" alt="" width="150px" height="110px" className="me-5" />
          <img src="./Images/Images/Artboard 1@3x 3.png" alt="" width="150px" height="110px" className="me-5" />
          <img src="./Images/Images/Artboard 1@3x 4.png" alt="" width="150px" height="110px" className="me-5" />
          <img src="./Images/Images/Artboard 1@3x 5.png" alt="" width="150px" height="110px" className="me-5" />
          <img src="./Images/Images/Artboard 1@3x 6.png" alt="" width="150px" height="110px" className="me-5" />
          <img src="./Images/Images/BM 1.png" alt="" width="150px" height="110px" />
        </marquee>
      </div>

    </div>
  );
}

