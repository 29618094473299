import React, { useEffect } from "react";
import WOW from "wowjs";

export default function Distinctiveness() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <div className="distin-main pt-5 pb-5">
        <div className="text-center mb-1 wow zoomIn">
          <span className="what" style={{ fontSize: "1.4em" }}>
            <img
              className="pe-1"
              src="./Images/Group 42.png"
              alt="Company Benefits"
            />
            WE ARE IN
          </span>
          <h1 className="font-bold" style={{ color: "rgba(57, 51, 123, 1)" }}>
            OUR DISTINCTIVENESS
          </h1>
        </div>
        <div className="DISTINCTIVENESS-sec ">
          <div className="container mt-2 pt-3">
            {/* <div className="d-flex flex-wrap justify-content-center align-items-center">
              <div className='distin-content row p-3'>
                <div className='col-3 p-0 w-0' style={{width:"10%"}}>
                  <img src="./Images/Group 91.png" alt="imge Not Found" width="90%" />
                </div>
                <div className='col-9 p-0 ps-2' style={{width:"88%"}}>
                  <h3 className='m-0' style={{color:"rgba(57, 51, 123, 1)" }} >Elevate Hub</h3>
                  <div>
                  We specialize in tailoring digital solutions to meet your unique business needs. Our expertise lies in seamlessly integrating innovative technologies, propelling your business towards sustained growth and success.
                  </div>
                </div>
              </div>
              <div className='distin-content row p-3'>
                <div className='col-3 p-0 w-0' style={{width:"10%"}}>
                  <img src="./Images/Group 91.png" alt="imge Not Found" width="90%" />
                </div>
                <div className='col-9 p-0 ps-2' style={{width:"88%"}}>
                  <h3 className='m-0' style={{color:"rgba(57, 51, 123, 1)" }} >Elevate Hub</h3>
                  <div>
                  We specialize in tailoring digital solutions to meet your unique business needs. Our expertise lies in seamlessly integrating innovative technologies, propelling your business towards sustained growth and success.
                  </div>
                </div>
              </div>
              <div className='distin-content row p-3'>
                <div className='col-3 p-0 w-0' style={{width:"10%"}}>
                  <img src="./Images/Group 91.png" alt="imge Not Found" width="90%" />
                </div>
                <div className='col-9 p-0 ps-2' style={{width:"88%"}}>
                  <h3 className='m-0' style={{color:"rgba(57, 51, 123, 1)" }} >Elevate Hub</h3>
                  <div>
                  We specialize in tailoring digital solutions to meet your unique business needs. Our expertise lies in seamlessly integrating innovative technologies, propelling your business towards sustained growth and success.
                  </div>
                </div>
              </div>
              <div className='distin-content row p-3'>
                <div className='col-3 p-0 w-0' style={{width:"10%"}}>
                  <img src="./Images/Group 91.png" alt="imge Not Found" width="90%" />
                </div>
                <div className='col-9 p-0 ps-2' style={{width:"88%"}}>
                  <h3 className='m-0' style={{color:"rgba(57, 51, 123, 1)" }} >Elevate Hub</h3>
                  <div>
                  We specialize in tailoring digital solutions to meet your unique business needs. Our expertise lies in seamlessly integrating innovative technologies, propelling your business towards sustained growth and success.
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row box-row " style={{ textAlign: "justify" }}>
              <div className="col-12 col-md-6 wow fadeInLeft">
                <div
                  className="distin-content row p-3"
                  style={{ float: "right" }}
                >
                  <div className="col-3 p-0 w-0" style={{ width: "10%" }}>
                    <img
                      src="./Images/Group 91.png"
                      alt="imge Not Found"
                      width="90%"
                    />
                  </div>
                  <div className="col-9 p-0 ps-2" style={{ width: "88%" }}>
                    <h3
                      className="m-0"
                      style={{ color: "rgba(57, 51, 123, 1)" }}
                    >
                      Elevate Hub
                    </h3>
                    <div style={{ fontSize: "1.1em",lineHeight:"1.6em",fontFamily:"Rubik",color:"#3D3D3D" }}>
                    We specialize in tailoring digital solutions to meet your
                      unique business needs.Our expertise lies in seamlessly integrating innovative technologies, propelling your
                      business towards sustained growth and success.
                   
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 wow fadeInRight">
                <div className="distin-content row p-3">
                  <div className="col-3 p-0 w-0" style={{ width: "10%" }}>
                    <img
                      src="./Images/Group 91.png"
                      alt="imge Not Found"
                      width="90%"
                    />
                  </div>
                  <div className="col-9 p-0 ps-2" style={{ width: "88%" }}>
                    <h3
                      className="m-0"
                      style={{ color: "rgba(57, 51, 123, 1)" }}
                    >
                      Progressive
                    </h3>
                    <div  style={{ fontSize: "1.1em",lineHeight:"1.6em",fontFamily:"Rubik",color:"#3D3D3D" }}>
                      Adapting to tech changes, optimizing with analytics, and
                      coordinating social media, email, content, SEO, and ads.
                      Tailoring messages, streamlining through automation,
                      prioritizing customer experience, and using creative
                      formats like interactive content, videos, and AR/VR.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row box-row">
              <div className="col-12 col-md-6 wow fadeInLeft">
                <div
                  className="distin-content row p-3"
                  style={{ float: "right" }}
                >
                  <div className="col-3 p-0 w-0" style={{ width: "10%" }}>
                    <img
                      src="./Images/Group 91.png"
                      alt="imge Not Found"
                      width="90%"
                    />
                  </div>
                  <div className="col-9 p-0 ps-2" style={{ width: "88%" }}>
                    <h3
                      className="m-0"
                      style={{ color: "rgba(57, 51, 123, 1)" }}
                    >
                      Honored Panel
                    </h3>
                    <div  style={{ fontSize: "1.1em",lineHeight:"1.6em",fontFamily:"Rubik",color:"#3D3D3D" }}>
                      Our team is not just proficient; they are driven by a
                      genuine passion to contribute to your success. We unite
                      professionals with varied expertise and backgrounds, all
                      committed to achieving outstanding outcomes for your
                      business.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 wow fadeInRight">
                <div className="distin-content row p-3">
                  <div className="col-3 p-0 w-0" style={{ width: "10%" }}>
                    <img
                      src="./Images/Group 91.png"
                      alt="imge Not Found"
                      width="90%"
                    />
                  </div>
                  <div className="col-9 p-0 ps-2" style={{ width: "88%" }}>
                    <h3
                      className="m-0"
                      style={{ color: "rgba(57, 51, 123, 1)" }}
                    >
                      Worldwide Outlook
                    </h3>
                    <div  style={{ fontSize: "1.1em",lineHeight:"1.6em",fontFamily:"Rubik",color:"#3D3D3D" }}>
                      With our extensive global footprint, we possess a
                      comprehensive viewpoint. We grasp the intricacies of
                      diverse markets, industries, and regions. This worldwide
                      perspective empowers us to provide innovative insights and
                      strategies to our clients.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
