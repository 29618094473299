import React,{useEffect} from 'react'
import WOW from "wowjs"

export default function Accordion() {

  useEffect(() => {
    new WOW.WOW({
        live: false
    }).init();
}, [])


  useEffect(() => {
    // Set the initial state of each accordion item
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach((button) => {
      const targetId = button.getAttribute('data-bs-target');
      const target = document.querySelector(targetId);

      if (!target.classList.contains('show')) {
        button.classList.add('collapsed');
        button.setAttribute('aria-expanded', 'false');
      }
    });
  }, []);
  return (
    <>
      <div className='artwave-tech pt-4 pb-3'>
        <div className="container">
          <div className="text-center mt-3 mb-4 wow zoomIn">
            <h1 className='font-bold' style={{ color: "white" }}>
              WHY ARTWAVE TECHNOLOGY?
            </h1>
          </div>

          <div className='container pt-2 d-flex justify-content-center wow fadeIn'>
            <div className="accordion custom-accordion" id="accordionExample">
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button collapsed" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)", boxShadow:"white"  }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <span style={{ color: "red" }}>1.</span>&nbsp; Innovation support
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Digital agencies play a crucial role in helping your  businesses establish and enhance  online presence. We as digital agencies offer a range of services, including web design, digital marketing, social media management, and more. Businesses often turn to digital agencies to leverage expertise in navigating the complexities of the digital landscape and reaching their target audience effectively.
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <span style={{ color: "red" }}>2.</span>&nbsp; E-business experts
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    We are on the cusp of a digital revolution, and the e-commerce market is growing rapidly. We focus on e-commerce development to support companies in the fierce and highly competitive online market. Whether we are building a new online store from scratch or optimizing an existing one, we have the knowledge and experience to deliver the best results.
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                    <span style={{ color: "red" }}>3.</span>&nbsp; Strategic Government Alliances
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Empowering governments with innovation, we're your trusted tech ally—crafting secure solutions that elevate public service, because progress begins with experience.
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                    <span style={{ color: "red" }}>4.</span>&nbsp; Collective acclaim
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Digital agencies provide corporate clients with comprehensive digital solutions to strengthen their online presence and achieve business objectives. This includes strategic services such as website development, online marketing, social media management, SEO optimization, and data analytics. By leveraging these digital tools, businesses can enhance brand visibility, engage with their target audience, and ultimately drive growth in the competitive online landscape. Digital agencies serve as partners, guiding corporations through the intricacies of the digital realm to maximize their impact and success.
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                    <span style={{ color: "red" }}>5.</span>&nbsp; Adapted solutions
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    We believe that one-size-fits-all solutions are a thing of the past. Every business has unique needs, and we are experts in crafting tailored solutions that perfectly align with your specific requirements. Before embarking on any development project, we take the time to thoroughly understand your business, ensuring that our solutions are not just effective but also seamlessly integrated into your operations.
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                    <span style={{ color: "red" }}>6.</span>&nbsp; Industry-leading
                  </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Our impressive portfolio is a testament to our expertise and unwavering commitment to excellence. We have a proven track record of transforming startups into thriving enterprises, empowering businesses to maintain their competitive edge, and enabling public institutions to enhance their service delivery to their constituents. Our accomplishments speak volumes about the high standards we uphold and the significance of our work.
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                    <span style={{ color: "red" }}>7.</span>&nbsp; Concise communication
                  </button>
                </h2>
                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    We believe that effective communication is the cornerstone of successful projects. We recognize the paramount importance of transparency and openness in achieving project objectives. We will never withhold project status updates from you. We value your input and opinions and will keep you informed throughout the entire project lifecycle.
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" style={{ fontSize: "0.8em", color: "rgba(57, 51, 123, 1)" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                    <span style={{ color: "red" }}>8.</span>&nbsp; Globalize Enhances
                  </button>
                </h2>
                <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    In today's interconnected world, businesses have the opportunity to reach a global audience with their products and services. However, expanding into new markets can be a daunting task, especially if you don't have the experience or resources necessary to navigate the complexities of international marketing.
                  </div>
                </div>
              </div>

              {/* ... (other accordion items) */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
