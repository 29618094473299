import CountUp from 'react-countup';
import React, { useEffect, useState } from 'react'
import WOW from "wowjs"
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ScrollTrigger from 'react-scroll-trigger';


export default function HappyCutomers() {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])
    const [Start, setStart] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [percentage2, setPercentage2] = useState(0);
    const [percentage3, setPercentage3] = useState(0);
    
    const [isCounterStarted, setIsCounterStarted] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition >= 3800 && !isCounterStarted) {
                setIsCounterStarted(true);
                const intervalId = setInterval(() => {
                    if (percentage < 100) {
                        setPercentage((prevPercentage) => Math.min(prevPercentage + 1, 100))
                    }
                    if (percentage2 < 42) {
                        setPercentage2((prevPercentage) => Math.min(prevPercentage + 1, 42))
                    }
                    if (percentage3 < 97) {
                        setPercentage3((prevPercentage) => Math.min(prevPercentage + 1, 97))
                    }
                }, 100);
                return () => {
                    clearInterval(intervalId);
                };
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, [isCounterStarted]);

    return (
        <div className="container-fluid hc_main">
            <div className="sideImage">
                {/* eslint-disable-next-line */}
                <img src="./Images/SIDE DSIGN1.png" className='img-fluid OP50 wna_sideDign2 wow fadeInLeft' alt="Image not Found...! " />
            </div>

            {/* <div className="headerTex t"> */}
            <div className="text-center wow zoomIn" style={{ paddingTop: "50px" }}>
                <span className='what' style={{ fontSize: "1.5em" }}>
                    <img className='pe-1 ' src="./Images/Group 42.png" alt="Company Benefits" />
                    What our Clients say?
                </span>
                <h1 className='font-bold' style={{ color: "rgba(57, 51, 123, 1)", fontSize: "3.7em", zIndex: 9999 }}>
                    HAPPY CUTOMERS
                </h1>
            </div>
            {/* </div> */}

            <ScrollTrigger onEnter={() => { setStart(true) }} onExit={() => { setStart(false) }}>
                <div className="row patison_3">

                    <div className=" col-12 col-md-2 pc wow fadeInLeft">
                        <div className="pc_img">
                            <CircularProgressbar
                                value={percentage}
                                styles={{
                                    root: {},
                                    path: {
                                        stroke: `rgba(220, 33, 48, ${percentage / 100})`,
                                        strokeLinecap: 'butt',
                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                        transform: 'rotate(0turn)',
                                        transformOrigin: 'center center',
                                        strokeWidth: 4,
                                    },
                                    trail: {
                                        stroke: 'rgba(57, 51, 123, 1)',
                                        strokeWidth: 4,
                                        strokeLinecap: 'butt',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    text: {
                                        fill: 'rgba(57, 51, 123, 1)',

                                        fontSize: '1.8vw',
                                    },
                                    background: {
                                        fill: 'rgba(57, 51, 123, 1)',
                                    },
                                }}
                            />
                        </div>
                        <div className="pc_text">
                            <h2>
                                {Start && <CountUp delay={0.8} duration={20} end={100} />} +
                            </h2>
                            <p style={{fontFamily:"Rubik",fontSize:"1.3em",fontWeight:"Bold"}}>Projects Completed</p>
                        </div>
                    </div>

                    <div className=" col-12 col-md-2 ac wow fadeIn">
                        <div className="ac_img">
                            <CircularProgressbar
                                value={percentage2}


                                styles={{
                                    root: {},
                                    path: {
                                        stroke: `rgba(220, 33, 48, ${percentage / 100})`,
                                        strokeLinecap: 'butt',
                                        zIndex:"10000",
                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                        transform: 'rotate(0turn)',
                                        transformOrigin: 'center center',
                                        strokeWidth: 4,
                                    },
                                    trail: {
                                        stroke: 'rgba(57, 51, 123, 0.8)',
                                        zIndex:"99",
                                        strokeWidth: 4,
                                        strokeLinecap: 'butt',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    text: {
                                        fill: 'rgba(57, 51, 123, 1)',

                                        fontSize: '1.8vw',
                                    },
                                    background: {
                                        fill: 'rgba(57, 51, 123, 1)',
                                    },
                                }}
                            />
                        </div>
                        <div className="ac_text">
                            <h2>{Start && <CountUp delay={0.8} duration={20} end={42} />}+</h2>
                            <p  style={{fontFamily:"Rubik",fontSize:"1.3em",fontWeight:"Bold"}}>Active Clients</p>
                        </div>
                    </div>

                    <div className=" col-12 col-md-2 Hc wow fadeInRight">
                        <div className="Hc_img">
                            <CircularProgressbar
                                value={percentage3}
                                styles={{
                                    root: {},
                                    path: {
                                        stroke: `rgba(220, 33, 48, ${percentage3 / 100})`,
                                        strokeLinecap: 'butt',
                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                        transform: 'rotate(0turn)',
                                        transformOrigin: 'center center',
                                        strokeWidth: 4,
                                    },
                                    trail: {
                                        stroke: 'rgba(57, 51, 123, 0.8)',
                                        strokeWidth: 4,
                                        strokeLinecap: 'butt',
                                        transform: 'rotate(0.25turn)',
                                        transformOrigin: 'center center',
                                    },
                                    text: {
                                        fill: 'rgba(57, 51, 123, 0.8)',

                                        fontSize: '1.8vw',
                                    },
                                    background: {
                                        fill: 'rgba(57, 51, 123, 0.8)',
                                    },
                                }}
                            />
                        </div>
                        <div className="Hc_text">
                            <h2>{Start && <CountUp delay={0.8} duration={20} end={97} />}+</h2>
                            <p  style={{fontFamily:"Rubik",fontSize:"1.3em",fontWeight:"Bold"}}>Happy Clients</p>
                        </div>
                    </div>
                </div>
            </ScrollTrigger>
        </div>
    )
}

