import React, { useEffect } from 'react'
import WOW from "wowjs"
import Cart from './Cart'

export default function CardData() {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])

    const cData = [
        {
            titleImage: "./Images/Group 91.png",
            title: "UI/UX Design",
            bodyText: " At ArtWave Technology, we blend precision and innovation in UI/UX services. Our user-centric design ensures seamless interactions from wireframing to interface design. Tailored to each client's unique needs, our solutions exceed expectations. Elevate your digital experience at the intersection of art and technology with ArtWave."
        },
        {
            titleImage: "./Images/Group 91.png",
            title: "Graphic Design",
            bodyText: "At ArtWave Technology, we blend precision and innovation in UI/UX services. Our user-centric design ensures seamless interactions from wireframing to interface design. Tailored to each client's unique needs, our solutions exceed expectations. Elevate your digital experience at the intersection of art and technology with ArtWave."
        },
        {
            titleImage: "./Images/Group 91.png",
            title: "Digital Marketing",
            bodyText: "Elevate your brand with ArtWave's Digital Marketing expertise. From targeted SEO to engaging social campaigns, we craft compelling solutions that drive results. Our data-driven approach optimizes campaigns in real-time, ensuring an impactful online presence. Join us for marketing that speaks directly to your audience and boosts online success."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "SEO Services",
            bodyText: "At ArtWave Technology, we blend precision and innovation in UI/UX services. Our user-centric design ensures seamless interactions from wireframing to interface design. Tailored to each client's unique needs, our solutions exceed expectations. Elevate your digital experience at the intersection of art and technology with ArtWave."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "Web Development",
            bodyText: "ArtWave Technology: Full-stack expertise for tailored web solutions. From responsive designs to scalability, we adapt to your business needs. Cutting-edge technology, commitment to quality—your ideal partner for web development excellence."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "App Development",
            bodyText: "At ArtWave Technology, our App Development brings expertise and innovation. Customized solutions for diverse platforms, ensuring engaging user experiences. Collaborative processes from ideation to deployment. Your trusted partner for app development that exceeds expectations."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "WordPress Development",
            bodyText: "ArtWave Technology: Elevate your online presence with WordPress expertise beyond the ordinary. Our experts craft customized, responsive, and SEO-optimized websites, empowering you with robust and secure solutions. Your trusted partner for success in the dynamic landscape of WordPress development."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "Software Development",
            bodyText: "ArtWave redefines software development with full-cycle solutions. We specialize in crafting cutting-edge, user-centric software using agile methodologies for flexibility and quality. From scalable solutions to seamless integration, ArtWave is your trusted partner for software success—where innovation meets excellence."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "E-commerce Solutions",
            bodyText: "ArtWave Technology redefines e-commerce, offering end-to-end solutions with visually stunning, user-friendly online stores. Tailored to your brand, our focus on mobile commerce, advanced security, and scalability empowers your business. Partner with ArtWave for e-commerce success—where your growth is our priority."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "Content Management",
            bodyText: "At ArtWave Technology, our Content Management service simplifies digital content handling. With intuitive interfaces, robust features, and efficient workflows, we streamline content creation, editing, and publishing for a dynamic online presence. ArtWave's service is designed for simplicity and effectiveness, offering businesses a hassle-free approach to content management."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "Consulting Support",
            bodyText: "ArtWave Technology's Consulting Support is your pathway to success. Our expert consultants offer tailored solutions, industry knowledge, and collaborative guidance. With a commitment to continuous improvement, we're your trusted partner in strategic planning and business success."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "Business Management",
            bodyText: "Empower your success with ArtWave's Business Management—comprehensive solutions from strategic planning to operational efficiency. Leveraging data-driven insights and financial expertise, we collaborate for your goals. Experience innovation where success is our commitment."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "3D Modeling",
            bodyText: "Explore a world of possibilities with ArtWave's 3D modeling—expert craftsmanship, versatile applications, and visually realistic models tailored to your needs. From dynamic animations to precision in every project, our innovation meets visual excellence."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "Video Editing",
            bodyText: "Elevate your visual content with ArtWave's Video Editing—crafted with precision and creativity. From storytelling to advanced techniques, our team delivers customized, visually compelling videos. Experience innovation in impactful storytelling."
        }, {
            titleImage: "./Images/Group 91.png",
            title: "Advertising ",
            bodyText: "Elevate your brand with ArtWave's Advertising—captivating creativity across digital, print, and outdoor. Targeted strategies and compelling storytelling ensure resonance. We deliver measurable results through performance analytics and a collaborative approach. Redefine advertising with ArtWave—innovation meets effective engagement."
        }
    ]
    return (
        <div className="cart_raper">
            {/* eslint-disable-next-line */}
            <img src="./Images/SIDE DSIGN1.png" className='img-fluid  cart_raper_sideDign wow fadeInLeft' alt="Image not Found...! " />
            {/* eslint-disable-next-line */}
            <img src="./Images/SIDE DSIGN1.png" className='img-fluid  cart_raper_sideDign1 wow fadeInRight' alt="Image not Found...! " />

            <div className="Right_Bc_Imag1">
                <img src="./Images/side design 3.png" className='img-fluid wow fadeInLeft' alt="img not Found...! " />
            </div>
            <div className="container">
                <div className="row">

                    {cData.map((x) => (
                        <Cart titleImage={x.titleImage} title={x.title} bodyText={x.bodyText}   />
                    ))}
                </div>
            </div>
        </div>
    )
}
