import React, { useEffect } from 'react'
import WOW from "wowjs"
import './ServiceSectionC.css'

export default function ServiceSection() {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <div className='mt-5'>
      <div className="text-center mb-1 wow zoomIn header_text_all">
        <span className='what'>
          <img className='pe-1' src="./Images/Group 42.png" alt="Company Benefits" />
          What we're offering
        </span>
        <h1 className='font-bold'>
          Service Built Specially for <br /> your Business</h1>
      </div>

      <section
        class="elementor-section elementor-inner-section elementor-element elementor-element-d4e075b elementor-section-boxed elementor-section-height-default elementor-section-height-default tm-col-stretched-none tm-bg-color-over-image"
        data-id="d4e075b" data-element_type="section">
        <div class="elementor-container elementor-column-gap-extended">
          <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-88df971 tm-bg-color-over-image"
            data-id="88df971"
            data-element_type="column">
            <div
              class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-2ae4d70 image-position-default elementor-widget elementor-widget-tm-ele-cpt-services"
                data-id="2ae4d70"
                data-element_type="widget"
                data-widget_type="tm-ele-cpt-services.skin-style-current-theme3">
                <div
                  class="elementor-widget-container">
                  <div
                    class="tm-sc-services tm-sc-services-grid border-radius-around-box">
                    <div id="services-holder-324345"
                      class="isotope-layout grid-1 gutter-10 clearfix">
                      <div
                        class="isotope-layout-inner">
                        <div
                          class="isotope-item ">
                          <div
                            class="post-11708 services type-services status-publish has-post-thumbnail hentry">
                            <div
                              class="service-item-current-style3 service-block">
                              <div
                                class="inner-box">
                                <div
                                  class="image-box">
                                  <div
                                    class="icon">
                                    <img src="../../../svg/ux-design (2).svg"
                                      style={{ width: "100%", height: "70%" }}
                                      alt="ASDAS" />
                                  </div>
                                  <div
                                    class="image">
                                    {/* eslint-disable-next-line */}
                                    <img loading="lazy"
                                      decoding="async"
                                      width="550"
                                      height="550"
                                      src="/wallpaper/Intersect.png"
                                      class="img-fullwidth wp-post-image"
                                      alt
                                      // srcset="https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-550x550.jpg 550w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-150x150.jpg 150w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-1100x1100.jpg 1100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-300x300.jpg 300w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-100x100.jpg 100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-600x600.jpg 600w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-96x96.jpg 96w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg13-460x460.jpg 460w"
                                      sizes="(max-width: 550px) 100vw, 550px" />
                                  </div>
                                </div>
                                <div
                                  class="content">
                                  <h5
                                    class="title service-title">
                                    <a
                                      href="##">UI
                                      /
                                      UX
                                       <br />
                                      Design</a>
                                  </h5>
                                  <div
                                    class="excerpt service-details" style={{fontFamily:"Rubik",fontSize:"1em",lineHeight:"1.6em"}}>
                                    ArtWave Technology's UI/UX design services create seamless digital experiences, blending aesthetics and functionality to captivate users. Elevate your brand with intuitive and visually appealing interfaces, enhancing user engagement and satisfaction
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="elementor-element elementor-element-044354e image-position-top-right elementor-widget elementor-widget-tm-ele-cpt-services"
                data-id="044354e"
                data-element_type="widget"
                data-widget_type="tm-ele-cpt-services.skin-style-current-theme3">
                <div
                  class="elementor-widget-container">
                  <div
                    class="tm-sc-services tm-sc-services-grid border-radius-around-box">
                    <div id="services-holder-922207"
                      class="isotope-layout grid-1 gutter-10 clearfix">
                      <div
                        class="isotope-layout-inner">
                        <div
                          class="isotope-item ">
                          <div
                            class="post-11707 services type-services status-publish has-post-thumbnail hentry">
                            <div
                              class="service-item-current-style3 service-block">
                              <div
                                class="inner-box">
                                <div
                                  class="image-box">
                                  <div
                                    class="icon">
                                    <img src="../../../svg/coding (1).svg" style={{ width: "100%", height: "70%" }} alt="ASDAS" />
                                  </div>
                                  <div
                                    class="image">
                                    {/* eslint-disable-next-line */}
                                    <img loading="lazy"
                                      decoding="async"
                                      width="550"
                                      height="550"
                                      src="/wallpaper/Intersect_(1).png"
                                      class="img-fullwidth wp-post-image"
                                      alt
                                      // srcset="https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-550x550.jpg 550w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-150x150.jpg 150w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-1100x1100.jpg 1100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-300x300.jpg 300w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-100x100.jpg 100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-600x600.jpg 600w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-96x96.jpg 96w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg23-460x460.jpg 460w"
                                      sizes="(max-width: 550px) 100vw, 550px" />
                                  </div>
                                </div>
                                <div
                                  class="content">
                                  <h5
                                    class="title service-title">
                                    <a
                                      href="##">Website <br />
                                      Development</a>
                                  </h5>
                                  <div
                                    class="excerpt service-details" style={{fontFamily:"Rubik",fontSize:"1em",lineHeight:"1.4em"}}>
                                    At ArtWave Technology, we specialize in crafting transformative websites tailored to your business needs. Our expert team blends cutting-edge tech and best practices to create sites that marry aesthetics with functionality. Whether it's a sleek business site, engaging portfolio, or dynamic e-commerce platform,
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4cb1159 tm-bg-color-over-image"
            data-id="4cb1159"
            data-element_type="column">
            <div
              class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-6c92907 image-position-bottom-left elementor-widget elementor-widget-tm-ele-cpt-services"
                data-id="6c92907"
                data-element_type="widget"
                data-widget_type="tm-ele-cpt-services.skin-style-current-theme3">
                <div
                  class="elementor-widget-container">
                  <div
                    class="tm-sc-services tm-sc-services-grid border-radius-around-box">
                    <div id="services-holder-853624"
                      class="isotope-layout grid-1 gutter-10 clearfix">
                      <div
                        class="isotope-layout-inner">
                        <div
                          class="isotope-item ">
                          <div
                            class="post-11706 services type-services status-publish has-post-thumbnail hentry">
                            <div
                              class="service-item-current-style3 service-block">
                              <div
                                class="inner-box">
                                <div
                                  class="image-box">
                                  <div
                                    class="icon">
                                    <img src="../../../svg/digital-marketing (1).svg" style={{ width: "100%", height: "70%" }} alt="ASDAS" />
                                  </div>
                                  <div
                                    class="image">
                                    {/* eslint-disable-next-line */}
                                    <img loading="lazy"
                                      decoding="async"
                                      width="550"
                                      height="550"
                                      src="/wallpaper/Intersect_(4).png"
                                      class="img-fullwidth wp-post-image"
                                      alt
                                      // srcset="https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-550x550.jpg 550w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-150x150.jpg 150w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-1100x1100.jpg 1100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-300x300.jpg 300w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-100x100.jpg 100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-600x600.jpg 600w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-96x96.jpg 96w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg1-460x460.jpg 460w"
                                      sizes="(max-width: 550px) 100vw, 550px" />
                                  </div>
                                </div>
                                <div
                                  class="content">
                                  <h5
                                    class="title service-title">
                                    <a
                                      href="##">
                                      Digital <br />
                                      Marketing</a>
                                  </h5>
                                  <div
                                    class="excerpt service-details" style={{fontFamily:"Rubik",fontSize:"1em",lineHeight:"1.6em"}}>
                                    Our skilled team employs data-driven strategies, SEO, SEM, and social media tactics to enhance your online presence, attract targeted audiences, and drive conversions, ensuring your business flourishes in the digital landscape.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="elementor-element elementor-element-f4f4431 image-position-top-left elementor-widget elementor-widget-tm-ele-cpt-services"
                data-id="f4f4431"
                data-element_type="widget"
                data-widget_type="tm-ele-cpt-services.skin-style-current-theme3">
                <div
                  class="elementor-widget-container">
                  <div
                    class="tm-sc-services tm-sc-services-grid border-radius-around-box">
                    <div id="services-holder-133038"
                      class="isotope-layout grid-1 gutter-10 clearfix">
                      <div
                        class="isotope-layout-inner">
                        <div
                          class="isotope-item ">
                          <div
                            class="post-11704 services type-services status-publish has-post-thumbnail hentry">
                            <div
                              class="service-item-current-style3 service-block">
                              <div
                                class="inner-box">
                                <div
                                  class="image-box">
                                  <div
                                    class="icon">
                                    <img src="../../../svg/web-design (1).svg" style={{ width: "100%", height: "70%" }} alt="ASDAS" />
                                  </div>
                                  <div
                                    class="image">
                                    {/* eslint-disable-next-line */}
                                    <img loading="lazy"
                                      decoding="async"
                                      width="550"
                                      height="550"
                                      src="/wallpaper/Intersect (3).png"
                                      class="img-fullwidth wp-post-image"
                                      alt
                                      // srcset="https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-550x550.jpg 550w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-150x150.jpg 150w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-1100x1100.jpg 1100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-300x300.jpg 300w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-100x100.jpg 100w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-600x600.jpg 600w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-96x96.jpg 96w, https://kodesolution.com/2023/mizox/wp-content/uploads/2023/01/bg2-460x460.jpg 460w"
                                      sizes="(max-width: 550px) 100vw, 550px" />
                                  </div>
                                </div>
                                <div
                                  class="content">
                                  <h5
                                    class="title service-title">
                                    <a
                                      href="##">Graphics  <br />Design</a>
                                  </h5>
                                  <div
                                    class="excerpt service-details" style={{fontFamily:"Rubik",fontSize:"1em",lineHeight:"1.6em"}}>
                                    At ArtWave Technology, we craft captivating visual stories, breathing life into your brand through expert Graphic Design. From striking logos to immersive website graphics, each pixel reflects your unique identity. Elevate your brand with ArtWave's precision and creativity.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
