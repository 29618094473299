import React, { useEffect } from 'react'
import WOW from "wowjs"
import YourProject from './YourProject';
import CustomSlider from './Slider';

export default function CustomerFeedbacks() {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])

    return (
        <div className='Home_backgroundImg'>
            <div className="sideImage wow fadeInLeft">
                {/* eslint-disable-next-line */}
                <img src="./Images/SIDE DSIGN1.png" className='img-fluid  wna_sideDign2' alt="Image not Found...! " />
            </div>

            <div className="text-center  wow zoomIn mt-2">
          <span className='what' >
            <img className='pe-1' src="./Images/Group 42.png" alt="Company Benefits" />
            Our Cherished          </span>
          <h1 className='font-bold' style={{ color: "rgba(57, 51, 123, 1)" }}>
            CUSTOMER  FEEDBACK
          </h1>
        </div>

            <CustomSlider />
            <div>
                <YourProject />
            </div>

        </div>
    )
}
