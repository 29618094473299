import React,{useEffect} from 'react'
import WOW from "wowjs"

export default function Citys() {

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])


    return (
        <>
            <div className='City-sec pt-5 pb-5'>
                <div className="container mt-1 pb-4">
                    <div className="text-center mb-4 wow zoomIn">
                        <span className='what'>
                            <img className='pe-1' src="./Images/Group 42.png" alt="Company Benefits" />
                            WE ARE IN
                        </span>
                        <h1 className='font-bold' style={{ color: "rgba(57, 51, 123, 1)" }}>
                            We're more than<br />an agency
                        </h1>
                    </div>

                    <div className='two-imgSec d-flex flex-wrap justify-content-between text-center align-items-center'>
                        <div className='city-img-content text-center ms-5 wow fadeInLeft'>
                            <img src="/Images/Rectangle 27.png" style={{ objectFit: "contain" }} className='img-fluid' alt="Image Not Found" />

                            <div className='p-2 fs-1 fw-bold text-dark'>AHMEDABAD</div>
                        </div> 
                        <div className='city-img-content text-center me-5 wow fadeInRight'>
                            <img src="/Images/Rectangle 28.png" style={{ objectFit: "contain" }} className='img-fluid' alt="Image Not Found" />

                            <div className='p-2 fs-1 fw-bold text-dark'>SURAT</div>
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}
