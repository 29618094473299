import React, { useEffect } from 'react'
import WOW from "wowjs"

export default function ServiceBuiltSB() {
    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])
    return (
        <div className="container-fluid ElevateReper wow zoomIn">
            {/* <div className="text-center" style={{ paddingTop: "50px" }}>
                <span className='what' style={{ fontSize: "1.3vw" }}>
                    <img className='pe-1' src="./Images/Group 42.png" alt="Company Benefits" />
                    What we're offering
                </span>
                <h1 className='font-bold' style={{ color: "rgba(57, 51, 123, 1)", fontSize: "5vw" }}>
                    Service Built Specifically for <br />
                    you Business
                </h1>
            </div> */}
            <div className="text-center mb-1 wow zoomIn header_text_all">
                <span className='what'>
                    <img className='pe-1' src="./Images/Group 42.png" alt="Company Benefits" />
                    What we're offering
                </span>
                <h1 className='font-bold'>
                    Service Built Specially for  your Business</h1>
            </div>
        </div>
    )
}
