import React from 'react'
import ServiceSection from '../HomeComponent/ServiceSection'
import WnAService from '../HomeComponent/WnAService'
import CompnyBenifits from '../HomeComponent/CompnyBanifits'
import Poster from '../HomeComponent/Poster'
import Questions from '../HomeComponent/Questions'
import Poster2 from '../HomeComponent/Poster2'
import HappyCutomers from '../HomeComponent/HappyCutomers'
import Citys from '../HomeComponent/Citys'
import CustomerFeedbacks from '../HomeComponent/CustomerFeedbacks'
import Accordion from '../HomeComponent/Accordion'
import Distinctiveness from '../HomeComponent/Distinctiveness'
import VerticalSlider from '../Slider/Slider'


export default function Home() {
  return (
    <>
      {/* <Banner /> */}
      <VerticalSlider/>
      <WnAService />
      <ServiceSection />
      <Poster />
      <CompnyBenifits />
      <Questions />
      <Poster2 />
      <HappyCutomers />
      <Citys />
      <Accordion />
      <Distinctiveness />
      <CustomerFeedbacks />
    </>
  )
}
