import React, { useEffect } from "react";
import WOW from "wowjs";
import CardData from "../AboutComponents/CardData";
import YourProject from "../HomeComponent/YourProject";
import Marqslider from "../HomeComponent/Marqslider";

export default function Service() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const post2Styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    post2_img: {
      width: "100%",
      objectFit: "contain",
      filter: "brightness(50%)",
    },
    post2_h1: {
      color: "white",
      position: "absolute",
      fontSize: "5vw",
      zIndex: "555",
      textAlign: "center",
    },
    colorSpan: {
      color: " #F08518",
    },
  };

  return (
    <div className="About_container">
      <div className="row p-0 m-0 wow fadeIn">
        <div
          className="col-12 p-0 m-0 position-relative"
          style={post2Styles.container}
        >
          <img
            src="./Images/architectcafecasualbrainstormingmeetingconcept-1@2x.png"
            alt="not found ...!"
            className="img-fluid"
            style={post2Styles.post2_img}
          />
          <h1 className="post_h1" style={post2Styles.post2_h1}>
            DIGITAL <span style={{ color: "#F08518" }}>MARKETING SERVICES</span>{" "}
            | <br />
            ARTWAVE TECHNOLOGY
          </h1>
        </div>
      </div>
      <div className="container-fluid ElevateReper wow zoomIn">
        <div className="text-center" style={{ paddingTop: "50px" }}>
          {/* <span className='what' style={{ fontSize: "1.3vw" }}>
                    <img className='pe-1' src="./Images/Group 42.png" alt="Company Benefits" />
                    What we're offering
                </span> */}
          <h1
            className="font-bold FS"
            style={{ color: "rgba(57, 51, 123, 1)", fontSize: "4vw" }}
          >
            Service We Provide
          </h1>
        </div>
      </div>

      <div className="About_backgroundImg">
        {/* <Elevate />          section 3  */}
        {/* <ServiceBuiltSB />    */}
        <CardData />
      </div>

      <Marqslider />
      <div style={{ marginTop: "25px" }}>
        <YourProject />
      </div>

      {/* <CustomerFeedbacks /> */}
    </div>
  );
}
