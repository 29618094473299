import React from "react";
import Slider from "react-slick";

export default function CustomSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="container slicerclass pt-5 "
      style={{ zIndex: 1000000000000 }}
    >
      <Slider {...settings}>
        <div className="m-1 d-flex">
          <div className="slider_reper">
            <div className="sHeader">
              <div className="bigIcon">
                <img
                  src="./Images/Google__G__logo 1.png"
                  alt="N/A"
                  className=" img-fluid  google"
                />
                <div className="man">
                  <img
                    src="./Images/vinit.png"
                    alt="N/A"
                    style={{ zIndex: 9999999999999 }}
                    className="img-fluid feedback-img"
                  />
                </div>
                <p className="name">Vinit Patel</p>
                <img
                  src="./Images/Group 98.png"
                  alt="N/A"
                  className=" img-fluid  star "
                />
              </div>
            </div>

            <div className="body text-light p-2 m-2">
              <p
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  fontSize: "0.8em",
                  lineHeight: "1.6em",
                }}
              >
                Working with ArtWave Technology over the past year has been a
                remarkable experience. From the outset to the final
                implementation , their team exhibited unmatched professionalism,
                expertise, and dedication in delivering exceptional IT
                solutions.
              </p>
            </div>
          </div>
        </div>

        <div className="m-1 d-flex">
          <div className="slider_reper">
            <div className="sHeader">
              <div className="bigIcon">
                <img
                  src="./Images/Google__G__logo 1.png"
                  alt="N/A"
                  className=" img-fluid  google"
                />
                <div className="man">
                  <img
                    src="./Images/paras.png"
                    alt="N/A"
                    style={{ zIndex: 9999999999999 }}
                    className=" img-fluid feedback-img "
                  />
                </div>
                <p className="name">Paras Patel</p>
                <img
                  src="./Images/Group 98.png"
                  alt="N/A"
                  className=" img-fluid  star "
                />
              </div>
            </div>

            <div className="body text-light p-2 m-2">
              <p
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  fontSize: "0.8em",
                  lineHeight: "1.6em",
                }}
              >
                I have had the pleasure of working with ArtWaveTechnology for
                the past year, they have exceeded my expectations in every
                aspect. From the initial consultation to the final
                implementation, the team at ArtWaveTechnology has demonstrated
                unparalleled professionalism, and dedication.
              </p>
            </div>
          </div>
        </div>

        <div className="m-1 d-flex">
          <div className="slider_reper">
            <div className="sHeader">
              <div className="bigIcon">
                <img
                  src="./Images/Google__G__logo 1.png"
                  alt="N/A"
                  className=" img-fluid  google"
                />
                <div className="man">
                  <img
                    src="./Images/Dhruv_Patolia-crop.png"
                    alt="N/A"
                    style={{ zIndex: 9999999999999 }}
                    className="img-fluid feedback-img dhruv-image"
                  />
                </div>
                <p className="name">Dhruv Patolia</p>
                <img
                  src="./Images/Group 98.png"
                  alt="N/A"
                  className=" img-fluid  star "
                />
              </div>
            </div>

            <div className="body text-light p-2 m-2">
              <p
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  fontSize: "0.8em",
                  lineHeight: "1.6em",
                }}
              >
                I recently had the pleasure of working with ArtWave Technology for my design needs, and I must say, I couldn't be happier with the results. From start to finish, their team demonstrated exceptional creativity, professionalism, and attention to detail. They truly have a knack for turning ideas into captivating designs that exceed expectations.
              </p>
            </div>
          </div>
        </div>

        <div className="m-1 d-flex">
          <div className="slider_reper">
            <div className="sHeader">
              <div className="bigIcon">
                <img
                  src="./Images/Google__G__logo 1.png"
                  alt="N/A"
                  className=" img-fluid  google"
                />
                <div className="man">
                  <img
                    src="./Images/Pratik.png"
                    alt="N/A"
                    style={{ zIndex: 9999999999999 }}
                    className=" img-fluid feedback-img"
                  />
                </div>
                <p className="name">Pratik Jadav</p>
                <img
                  src="./Images/Group 98.png"
                  alt="N/A"
                  className=" img-fluid  star "
                />
              </div>
            </div>

            <div className="body text-light p-2 m-2">
              <p
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  fontSize: "0.8em",
                  lineHeight: "1.6em",
                }}
              >
                I recently had the pleasure of doing business with ArtArtwave
                technology,and I must say that it was a remarkable experience
                from start to finish.rare to find a company that is not only
                highly skilled technically but also deeply invested in building
                long-term relationships with their clients.
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}
