import React from "react";
import Slider from "react-slick";

const VerticalSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    fade: true,
    autoplay: true,
    speed:1500,
    autoplaySpeed: 4000,
    onmouseenter:false,
    onmouseleave:true
  };

  return (
    <div className="container-fluid home-slider p-0 m-0">
      <Slider {...settings}>

        <div>
          <img src='./svg/7378401_31958 1.jpg' alt="not Found" className="img-fluid" />
        </div>

        <div>
          <img src='./Images/architectcafecasualbrainstormingmeetingconcept-1@2x.png' alt="not Found" className="img-fluid" />
        </div>

        <div>
          <img src='./svg/7378401_31958 1.jpg' alt="not Found" className="img-fluid" />
        </div>

        <div>
          <img src='./Images/architectcafecasualbrainstormingmeetingconcept-1@2x.png' alt="not Found" className="img-fluid" />
        </div>

      </Slider>
    </div>
  );
};

export default VerticalSlider;

