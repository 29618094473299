import React, { useEffect, useState } from "react";
import WOW from "wowjs";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    message: "",
    Email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    // e.preventDefault();

    const phoneNumber = "+919099136235";

    const message = `New form submission:\nName: ${formData.name}\nEmail: ${formData.Email}\nMessage: ${formData.message}`;

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.location.href = whatsappUrl;
    console.log("paras");
  };

  const ContactStyles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    conHeader_img: {
      width: "100%",
      objectFit: "contain",
      filter: "brightness(50%)",
    },
    conHeader_h1: {
      color: "white",
      position: "absolute",
      fontSize: "5vw",
      zIndex: "555",
      textAlign: "center",
    },
    background_image: {
      backgroundImage: 'url("./Images/wallpaper our distinctiveness.png")',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingBottom: "5%",
    },
  };

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row p-0 m-0 wow fadeIn">
        <div
          className="col-12 p-0 m-0 position-relative"
          style={ContactStyles.container}
        >
          {/* eslint-disable-next-line */}
          <img
            src="./Images/architectcafecasualbrainstormingmeetingconcept-1@2x.png"
            alt="Image is not found ...!"
            className="img-fluid"
            style={ContactStyles.conHeader_img}
          />
          <h1 className="post_h1" style={ContactStyles.conHeader_h1}>
            <span style={{ color: "#F08518" }}>CONTACT</span> ARTWAVE TECHNOLOGY
          </h1>
        </div>
      </div>

      <div className="row m-0 wow " style={ContactStyles.background_image}>
        <div className="row col-12 col-sm-6 p-0 pt-4 col-md-6 col-lg-6 col-sm-12 wow fadeInLeft">
          <div className="effect col-3 p-0">
            {/* eslint-disable-next-line */}
            <img
              src="./Images/Group 122.png"
              className="img-fluid p-0"
              style={{ width: "10vw" }}
            />
          </div>
          <div className="col-9 px-0">
            <div
              style={{
                paddingTop: "30px",
                fontSize: "3.6rem",
                color: "rgba(57, 51, 123, 1)",
                lineHeight: "4rem",
                fontWeight: "700",
              }}
            >
              SHARE YOUR NEEDS <br />
              WITH US.
            </div>
            <div
              className="contact-content col-10"
              style={{
                color: "rgba(61, 61, 61, 1)",
                lineHeight: "1.4em",
                fontFamily: "sans-serif",
                fontWeight: "bold",
                fontSize: "1em",
                position: "relative",
                top: "1.5vw",
                marginBottom: "5px",
              }}
            >
              Positioned to metamorphose your startup or business into a success
              narrative, we stand ready to manifest your aspirations into
              tangible achievements. Embark on this transformative journey with
              ArtWave Technology, where dreams seamlessly evolve into reality.
            </div>
          </div>
        </div>

        <div
          className="col-12 col-sm-6 wow fadeInRight"
          style={{ position: "relative", top: "5vw" }}
        >
          <form>
            <input
              id="tel"
              type="email"
              placeholder="Email Address"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
            />
            <input
              id="eml"
              type="text"
              required
              placeholder="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />

            <textarea
              rows="6"
              required
              placeholder="Write Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </form>
          <div className="contactbtn2">
            <div
              className="rounded contactbtn1"
              style={{
                marginTop: "-10px",
                color: "whitesmoke",
                marginBottom: "3vw",
                textAlign: "center",
                backgroundColor: "#DC202F",
                borderRadius: "0.5vw",
              }}
            >
              <div
                class="elementor-element text-light elementor-element-6326ccd elementor-widget elementor-widget-tm-ele-button rounded"
                data-id="6326ccd"
                data-element_type="widget"
                data-widget_type="tm-ele-button.default"
              >
                <div class="elementor-widget-container  rounded">
                  <div
                    onClick={handleSubmit}
                    class="tm-sc-button btn-view-details contactbtn rounded "
                  >
                    <a class="btn text-light btn-theme-colored1 rounded " href="##">
                      <span style={{ fontSize: "1.2rem" }}>Send Message</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="row col-12 contactitem m-0 wow fadeInUp">
          <span>AHMEDABAD</span>
        </div>
        <div
          className="row m-0 col-12 "
          style={{ position: "relative", top: "0vw" }}
        >
          <div
            className="col-12 "
            style={{ position: "relative", top: "30px", zIndex: 100000 }}
          >
            <div className="row justify-content-evenly fw-bold">
              <div
                className="col-12 col-sm-3 text-center d-flex justify-content-center align-items-center con border shadow bg-light wow fadeInLeft"
                style={{ fontFamily: "Rubik" }}
              >
                <i className="bi bi-telephone-fill pe-2 text-danger"></i>
                +91 63522 07050
              </div>
              <div
                className="col312 col-sm-3 text-center d-flex justify-content-center align-items-center con border shadow bg-light wow fadeInUp"
                style={{ fontFamily: "Rubik" }}
              >
                <i className="bi bi-envelope-fill pe-2 text-danger"></i>
                aertwavetechnology@gmail.com
              </div>
              <div
                className="col-12 col-sm-3 text-center d-flex justify-content-center align-items-center con border shadow bg-light wow fadeInRight"
                style={{ fontFamily: "Rubik" }}
              >
                <i className="bi bi-geo-alt-fill pe-2 text-danger"></i>
                Ahmedabad,Gujarat,382350+
              </div>
            </div>
          </div>

          <div
            className="mt-sm-0 mt-5 "
            style={{ width: "100vw", padding: "0px", paddingBottom: "1%" }}
          >
            {/* eslint-disable-next-line */}
            <iframe
              id="frame"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.6969957128085!2d72.6773172906003!3d23.034895086320233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e873591fa2cc3%3A0x42ed92e097510dd7!2sKalp%20Industrial%20Estate!5e0!3m2!1sen!2sin!4v1707822914758!5m2!1sen!2sin"
              width="100%"
              height={650}
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div>
        <div className="row col-12 mt-sm-4 contactitem m-0 wow fadeInUp">
          <span>SURAT</span>
        </div>

        <div
          className="row m-0 col-12 "
          style={{ position: "relative", top: "0vw" }}
        >
          <div
            className="col-12 "
            style={{ position: "relative", top: "30px", zIndex: 100000 }}
          >
            <div className="row justify-content-evenly fw-bold">
              <div
                className="col-12 col-sm-3 text-center d-flex justify-content-center align-items-center con border shadow bg-light wow fadeInLeft"
                style={{ fontFamily: "Rubik" }}
              >
                <i className="bi bi-telephone-fill pe-2 text-danger"></i>
                +91 90239 89489
              </div>
              <div
                className="col-12 col-sm-3 text-center d-flex justify-content-center align-items-center con border shadow bg-light wow fadeInUp"
                style={{ fontFamily: "Rubik" }}
              >
                <i className="bi bi-envelope-fill pe-2 text-danger"></i>
                influenceimagine@gmail.com
              </div>
              <div
                className="col-12 col-sm-3 text-center d-flex justify-content-center align-items-center con border shadow bg-light wow fadeInRight"
                style={{ fontFamily: "Rubik" }}
              >
                <i className="bi bi-geo-alt-fill pe-2 text-danger"></i>
                Surat,Gujarat,395006
              </div>
            </div>
          </div>
          <div
            className="mt-sm-0 mt-5"
            style={{ width: "100vw", padding: "0px", paddingBottom: "1%" }}
          >
            {/* eslint-disable-next-line */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.6969957128085!2d72.6773172906003!3d23.034895086320233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e873591fa2cc3%3A0x42ed92e097510dd7!2sKalp%20Industrial%20Estate!5e0!3m2!1sen!2sin!4v1707822914758!5m2!1sen!2sin"
              width={"100%"}
              height={650}
              style={{ border: "0" }}
              allowFullScreen="true"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
